import React from "react";
import {Link} from 'gatsby';
import logo_cropped from '../../pages/logo-cropped.svg';
import moment from "moment";
import "./style.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import DynamicNavbar from "../DynamicNavBar";

class PostTemplateDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDesktop: false
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState(
            {isDesktop: window.innerWidth > 950}
        );
    }

    render() {
        const post = this.props.data.markdownRemark;
        const tags = post.fields.tagSlugs;
        const {prev, next} = this.props.pathContext;
        const creators = post.frontmatter.creators;
        const timeToRead = post.timeToRead;
        const image = post.frontmatter.image.childImageSharp.resize.src;
        const author = this.props.data.site.siteMetadata;
        const isDesktop = this.state.isDesktop;

        const homeBlock = (
            <React.Fragment>
                {isDesktop ? (
                    <div className="post-single__nav">
                        <Link to="/">
                            <img src={logo_cropped} alt={author.name} className="post-single__nav-logo"/>
                        </Link>
                        <ul className="post-single__nav-list">
                            <li className="post-single__nav-list-item">
                                <Link to="/">Alle Posts</Link>
                            </li>
                            <li className="post-single__nav-list-item">
                                <Link to="/categories">Alle Kategorien</Link>
                            </li>
                            <li className="post-single__nav-list-item">
                                <Link to="/tags">Alle Tags</Link>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <DynamicNavbar/>
                )}
            </React.Fragment>
        );

        const tagsBlock = (
            <div className="post-single__tags">
                <ul className="post-single__tags-list">
                    {tags && tags.map((tag, i) => (
                        <li className="post-single__tags-list-item" key={tag}>
                            <Link to={tag} className="post-single__tags-list-item-link">
                                {post.frontmatter.tags[i]}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );

        const readMoreBlock = (
            <div>
                <h2>Weitere Blogposts</h2>
                {next && (
                    <h4>
                        <FontAwesomeIcon icon={faChevronLeft} className="routing-icon"/>
                        <Link to={next.fields.slug}>
                            {next.frontmatter.title}
                        </Link>
                    </h4>
                )}
                {prev && (
                    <h4 className="text-right">
                        <Link to={prev.fields.slug}>
                            {prev.frontmatter.title}
                        </Link>
                        <FontAwesomeIcon icon={faChevronRight} className="routing-icon"/>
                    </h4>
                )}
            </div>
        );

        const creatorBlock = (
            <p className="post-single__footer-text">
                <h2>Verfasst von:</h2>
                {creators && creators.map(creator => {
                    return <div style={{display: 'flow-root'}}>
                        <p className="post-single__footer-text">
                            <img
                                src={creator.avatar.childImageSharp.resize.src}
                                alt={`Foto von ${creator.name}`}
                                style={{
                                    borderRadius: '100%',
                                    float: 'left',
                                    marginRight: '0.5em',
                                    marginBottom: '0',
                                    width: '100px',
                                    height: '100px'
                                }}
                            />
                            <h4 style={{margin: '0', fontWeight: 'bold'}}>
                                {creator.name}
                            </h4>
                            {creator.bio}
                        </p>
                    </div>
                })}
            </p>
        );

        return (
            <div>
                {homeBlock}
                <div className="post-single">
                    <div className="post-single__inner">
                        <h1 className="post-single__title">
                                  {post.frontmatter.title}
                        </h1>
                        <div className="post-single__date">
                            <small>
                                Lesezeit: {timeToRead} Min, veröffentlicht
                                am {moment(post.frontmatter.date).format('DD.MM.YYYY')}
                            </small>
                        </div>
                        <div className="post-single__body">
                            <img
                                style={{
                                    width: '100%'
                                }}
                                src={image} alt={post.frontmatter.title}/>
                        </div>

                        <div className="post-single__body" dangerouslySetInnerHTML={{__html: post.html}}/>
                    </div>
                    <div className="post-single__footer">

                        <small>Tags</small>
                        {tagsBlock}
                        <hr/>
                        {creatorBlock}
                        {readMoreBlock}
                    </div>
                </div>
            </div>
        )
            ;
    }
}

export default PostTemplateDetails;
