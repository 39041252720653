import React, { Component } from 'react';
import classnames from "classnames";
import {Link} from "gatsby";

export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        };
    }



    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const { prevScrollpos } = this.state;
        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos;
        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    };




    render() {
        return (
            <nav
                className={classnames("navbar", {
                "navbar--hidden": !this.state.visible
                })}
                >
                <ul className="post-single__nav-list">
                    <li className="post-single__nav-list-item">
                        <Link to="/">Alle Posts</Link>
                    </li>
                    <li className="post-single__nav-list-item">
                        <Link to="/categories">Alle Kategorien</Link>
                    </li>
                    <li className="post-single__nav-list-item">
                        <Link to="/tags">Alle Tags</Link>
                    </li>
                </ul>
            </nav>
        );
    }
}
